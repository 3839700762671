/* eslint-disable react/no-unknown-property */
import * as React from 'react'
import { useRouter } from 'next/router'
import {
  Maybe,
  Product,
  ShopifyVariantImage,
  ProductInfo,
  ShopifyProductDef,
} from '../../types'
import {
  getVariantTitle,
  parseHTML,
  definitely,
  getSelectedOptionValues,
  getProductUri,
  getAdditionalDescriptions,
  useProductVariant,
} from '../../utils'
import {
  useShopify,
  useAnalytics,
  CurrentProductProvider,
  useUser,
} from '../../providers'
import { CloudinaryAnimation } from '../../components/CloudinaryVideo'
import { Column } from '../../components/Layout'
import { RichText } from '../../components/RichText'
import { Affirm } from '../../components/Affirm'
import { Heading } from '../../components/Text'
import { AppointmentsButton } from './components/AppointmentsButton'

import {
  ProductVariantSelector,
  BuyButton,
  ProductImages,
  ProductDetailHeader,
  ProductDetailFooter,
  ProductRelated,
  RingSizerButton,
  SizeConverterButton,
  ProductRecent,
} from './components'
import { useShopData } from '../../providers/ShopDataProvider'
import { useCountry } from '../../providers/CountryProvider'
import { useModal } from '../../providers/ModalProvider'
import {
  ProductPageWrapper,
  AffirmWrapper,
  ProductDetails,
  ProductInfoWrapper,
  ProductImagesWrapper,
  ProductAccordionsWrapper,
  InfoWrapper,
  RingToolsWrapper,
} from './styled'
import { Accordion } from '../../components/Accordion'
import { SEO } from '../../components/SEO'
import { styled, css } from '@xstyled/styled-components'

interface VariantAnimation {
  __typename: 'CloudinaryVideo'
  videoId?: string
}
import { CustomizeButton } from './components/CustomizeButton'
import { Klarna } from '../../components/Klarna'

const { useEffect, useState } = React

const InStockDot = styled('span')`
  ${({ theme }) => css`
    display: inline-block;
    background-color: #00d009;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    border-radius: 100%;
    border: 1px solid #f5f3f3;
  `}
`
interface WithHide {
  $hide: boolean
}

const StockedLabelMobile = styled('div')<WithHide>`
  ${({ theme, $hide }) => css`
    display: none;
    margin-bottom: 4;
    opacity: ${$hide ? 0 : 1};
    transition: 250ms ease;
    font-size: ${theme.fontSizes[5]}px;
    ${theme.mediaQueries.tablet} {
      display: block;
    }
  `}
`

interface Props {
  product: Product
}

import { addRecentlyViewedProduct } from '../../utils/recentlyViewed'

const showInStockIndicators =
  process.env.NEXT_PUBLIC_SHOW_IN_STOCK_INDICATORS === 'true'

const slugify = (text?: Maybe<string>) => {
  if (!text) return ''
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const ProductDetail = ({ product }: Props) => {
  const { openCustomizationModal } = useModal()
  const { logCustomEvent } = useUser()
  const router = useRouter()
  const [playing, setPlaying] = useState(false)
  const [isInquiryOnly, setIsInquiryOnly] = useState(false)

  React.useEffect(() => {
    if (product?.handle !== router.query.productSlug) {
      router.reload()
    }
  }, [product?.handle, router])

  const params = new URLSearchParams(router.asPath.replace(/^(.*)\?/, ''))
  const variantId = params.get('v')

  const useProductVariantOptions =
    typeof variantId === 'string' ? { initialVariant: variantId } : undefined

  /* get additional info blocks from Sanity */
  const { sendProductDetailView } = useAnalytics()
  const { currentCountry, loading } = useCountry()
  const { getProductInfoBlocks } = useShopData()

  const productInfoBlocks = React.useMemo(() => {
    return getProductInfoBlocks(product)
  }, [getProductInfoBlocks, product])

  const { currentVariant, selectVariant } = useProductVariant(
    product,
    useProductVariantOptions,
  )

  const productType = product?.store?.productType
  const images = product?.store?.images || []
  const hidden = product?.hideFromSearch

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(loading)

    const timeout = setTimeout(() => {
      if (loading === isLoading) {
        setIsLoading(false)
      }
    }, 3000)

    return () => clearTimeout(timeout)
  }, [loading, isLoading])

  const { addLineItem } = useShopify()

  /* get product image variants from Shopify */
  const description = parseHTML(product?.store?.descriptionHtml)

  const selectedOptions = React.useMemo(() => {
    return currentVariant
      ? getSelectedOptionValues(product, currentVariant)
      : []
  }, [currentVariant, product])

  const optionDescriptions = React.useMemo(() => {
    return getAdditionalDescriptions(selectedOptions)
  }, [selectedOptions])

  const [variantAnimation, setVariantAnimation] = useState<VariantAnimation>({
    __typename: 'CloudinaryVideo',
  })
  const [variantHasAnimation, setVariantHasAnimation] = useState(false)

  useEffect(() => {
    const optionsWithAnimation =
      selectedOptions.filter((option) => option.animation) || []

    if (optionsWithAnimation.length > 0) {
      setVariantHasAnimation(true)
      setVariantAnimation((prevState) => ({
        ...prevState,
        videoId: optionsWithAnimation[0].animation ?? undefined,
      }))
    } else {
      setVariantHasAnimation(false)
      setVariantAnimation({ __typename: 'CloudinaryVideo' }) // Reset state
    }
  }, [selectedOptions]) // Effect will run when `selectedOptions` changes

  const stockedVariants = React.useMemo(() => {
    return product.store?.variants?.filter(
      (variant) =>
        variant?.sourceData?.availableForSale === true &&
        variant?.sourceData?.currentlyNotInStock === false &&
        !variant?.sourceData?.selectedOptions?.find(
          (o) => o?.value == 'Not sure of my size',
        ) &&
        !variant?.sourceData?.selectedOptions?.find((o) => o?.name == 'Carat'),
    )
  }, [product.store?.variants])

  const stockedColorOptions = React.useMemo(() => {
    return stockedVariants
      ?.map((variant) =>
        variant?.sourceData?.selectedOptions?.find(
          (option) => option?.name === 'Color',
        ),
      )
      .map((option) => slugify(option?.value))
  }, [stockedVariants])

  const productImages = React.useMemo(
    () => product.store?.images || [],
    [product.store?.images],
  )

  const posterImage = React.useMemo(() => {
    return currentVariant?.sourceData?.image
      ? currentVariant.sourceData.image
      : productImages.length
      ? productImages[0]
      : undefined
  }, [currentVariant?.sourceData?.image, productImages])

  useEffect(() => {
    // send custom event to Braze
    const customEventData = {
      title: currentVariant?.title || product.title,
      sku: currentVariant?.sourceData?.sku,
      price: currentVariant?.sourceData?.priceV2?.amount,
      size: currentVariant?.sourceData?.selectedOptions?.find(
        (option) => option?.name === 'Size',
      )?.value,
      product_title: product.title,
    }
    setTimeout(() => {
      const logEvent = logCustomEvent('view_product_page', customEventData)
    }, 3000)

    const paramString = router.asPath.replace(/^(.*)\?/, '')
    const params = new URLSearchParams(paramString)
    const timeout = setTimeout(() => {
      if (params.get('customizeOpen') === 'true') {
        openCustomizationModal({
          currentProduct: product,
          currentVariant: currentVariant || undefined,
        })
      }
    }, 1500)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (!currentVariant?.id || !product.shopifyId) return

    sendProductDetailView({ product, variant: currentVariant })

    const newUri = getProductUri(product, {
      variant: currentVariant,
      currentPath: router.asPath,
    })

    if (
      newUri &&
      window.location.pathname + window.location.search !== newUri
    ) {
      router.replace(newUri, undefined, { scroll: false, shallow: true })
    }

    addRecentlyViewedProduct(product.shopifyId, currentVariant.id)
  }, [
    product.shopifyId,
    currentVariant,
    product,
    sendProductDetailView,
    router,
  ])

  const productWithInquiryOverride = {
    ...product,
    inquiryOnly: isInquiryOnly ? isInquiryOnly : product.inquiryOnly,
  }

  const { seo, handle } = product

  const { inquiryOnly } = productWithInquiryOverride

  const maybeVariants = product?.store?.variants
  const variants = definitely(maybeVariants)
  const { currentlyNotInStock } = currentVariant?.sourceData ?? {}
  const variantsInStock =
    variants?.filter(
      (v) =>
        v?.sourceData?.currentlyNotInStock === false &&
        !v?.sourceData?.selectedOptions?.find(
          (o) => o?.value == 'Not sure of my size',
        ) &&
        !v?.sourceData?.selectedOptions?.find((o) => o?.name == 'Carat'),
    ) || []

  const isSwatchCurrentlyInStock = (
    currentVariant,
    stockedOptions,
    stockedVariants,
  ): boolean => {
    if (
      currentVariant.sourceData?.selectedOptions?.find(
        (option) => option.name === 'Color',
      ) !== undefined
    ) {
      const color = slugify(
        currentVariant.sourceData.selectedOptions.find(
          (option) => option.name === 'Color',
        ).value,
      )

      return stockedOptions.includes(color)
    } else {
      return Boolean(stockedVariants?.length > 0)
    }
  }

  const changeValueForOption = (optionName: string) => (newValue: string) => {
    const previousOptions = currentVariant?.sourceData?.selectedOptions || []
    if (!product.store) {
      throw new Error('Product was loaded without store')
    }
    const variants = product.store.variants || []

    const newOptions = definitely(previousOptions).map(({ name, value }) => {
      if (name !== optionName) return { name, value }
      return { name, value: newValue }
    })

    const newVariant = variants.find((variant) => {
      const selectedOptions = variant?.sourceData?.selectedOptions
      if (!selectedOptions) return false

      const match = newOptions.every(({ name, value }) =>
        selectedOptions.some(
          (so) => so && so.name === name && so.value === value,
        ),
      )
      return match
    })

    // If a variant match is not found, find the best option based on the updated selection.
    // This can happen if a particular option does not exist, i.e.:
    // - Color: Black Gold, Size: 1
    // - Color: Black Gold, Size: 2
    // - Color: Yellow Gold, Size: 2
    //
    // If a user has currently selected BG/1, then changes the color option to "Yellow Gold",
    // YG/1 does not exist, so, default to YG/2
    const bestVariant = newVariant
      ? newVariant
      : variants.find((variant) => {
          const selectedOptions = variant?.sourceData?.selectedOptions

          if (!selectedOptions) return false
          const match = Boolean(
            selectedOptions.find(
              (so) => so && so.name === optionName && so.value === newValue,
            ),
          )
          return match
        })

    if (!bestVariant || !bestVariant.id) {
      throw new Error('No variant was found for these options')
    }
    selectVariant(bestVariant.id)
  }

  const defaultSeo = {
    title: currentVariant
      ? `${getVariantTitle(product, currentVariant)} | Spinelli Kilcollin`
      : '',
    image:
      currentVariant?.sourceData?.image ?? images.length
        ? (currentVariant?.sourceData?.image as ShopifyVariantImage)
        : images[0],
  }

  if (!handle) throw new Error('No handle fetched')
  const basePath = ['products', handle].join('/')
  const path = currentVariant?.shopifyVariantID
    ? basePath.concat('?v=').concat(currentVariant.shopifyVariantID)
    : basePath

  const weddingMatch = product.store?.tags?.some((tag) => tag === 'wedding')
  const isGiftCard = product.store?.productType === 'Gift Card'

  const filterProductInfoBlocksByTag = (
    productInfoBlocks: ProductInfo[],
    productTags: ShopifyProductDef['tags'],
    filters: { [key: string]: string },
  ): any[] => {
    return productInfoBlocks.filter((block) => {
      if (!productTags || !productTags.length) {
        return
      }

      const matchingCriteriaKey = Object.keys(filters).find((key) =>
        block?.title?.toLowerCase().includes(key.toLowerCase()),
      )

      if (matchingCriteriaKey) {
        const filterTag = filters[matchingCriteriaKey]
        return !productTags.includes(filterTag)
      }

      return true
    })
  }

  const filteredProductInfoBlocks = filterProductInfoBlocksByTag(
    productInfoBlocks,
    product?.store?.tags,
    {
      Customization: 'BFCM24',
      'Jewelry Lead Time': 'NoProduction',
    },
  )

  const accordions = filteredProductInfoBlocks
    ? filteredProductInfoBlocks
    : productInfoBlocks

  if (!currentVariant) return null
  // if (loading) return null

  return (
    <>
      <SEO
        seo={seo}
        defaultSeo={defaultSeo}
        path={path}
        contentType="product"
        product={product}
        currentVariant={currentVariant}
        hidden={hidden}
      />
      <CurrentProductProvider product={product} currentVariant={currentVariant}>
        <ProductPageWrapper tabIndex={-1} $loading={isLoading}>
          {!isLoading && (
            <Column>
              <ProductDetails>
                <ProductImagesWrapper>
                  {variantHasAnimation && variantAnimation?.videoId ? (
                    <CloudinaryAnimation
                      video={variantAnimation}
                      image={posterImage}
                      setPlaying={setPlaying}
                      screen="desktop"
                    />
                  ) : null}

                  <ProductImages
                    currentVariant={currentVariant}
                    product={product}
                    screen="desktop"
                    $hide={Boolean(
                      variantHasAnimation && variantAnimation?.videoId,
                    )}
                  />
                </ProductImagesWrapper>

                <InfoWrapper
                  $product={product}
                  className={isGiftCard ? 'gift-card-info-wrapper' : undefined}
                >
                  <ProductDetailHeader
                    currentVariant={currentVariant}
                    currentCountry={currentCountry}
                    product={productWithInquiryOverride}
                  />

                  {variantHasAnimation && variantAnimation?.videoId ? (
                    <CloudinaryAnimation
                      video={variantAnimation}
                      image={posterImage}
                      setPlaying={setPlaying}
                      screen="mobile"
                    />
                  ) : null}

                  <ProductImages
                    currentVariant={currentVariant}
                    product={product}
                    screen="mobile"
                    $hide={Boolean(
                      variantHasAnimation && variantAnimation?.videoId,
                    )}
                  />

                  <ProductInfoWrapper>
                    {variantsInStock?.length > 0 && showInStockIndicators ? (
                      <StockedLabelMobile
                        $hide={
                          !isSwatchCurrentlyInStock(
                            currentVariant,
                            stockedColorOptions,
                            stockedVariants,
                          )
                        }
                      >
                        <Heading level={4} weight={1} as={'em'}>
                          <InStockDot />
                          {currentlyNotInStock !== true &&
                          !currentVariant.title?.includes('Not sure of my size')
                            ? 'In Stock'
                            : 'In Stock in Select Sizes'}
                        </Heading>
                      </StockedLabelMobile>
                    ) : null}
                    <ProductVariantSelector
                      variants={variants}
                      currentVariant={currentVariant}
                      changeValueForOption={changeValueForOption}
                      product={product}
                      setIsInquiryOnly={setIsInquiryOnly}
                    />
                    {productType === 'Ring' ? (
                      <RingToolsWrapper>
                        <RingSizerButton
                          product={product}
                          variant={currentVariant}
                        />
                        <SizeConverterButton
                          product={product}
                          variant={currentVariant}
                          addLineItem={addLineItem}
                        />
                        <AppointmentsButton />
                        <CustomizeButton
                          product={product}
                          variant={currentVariant}
                          wedding={weddingMatch}
                        />
                      </RingToolsWrapper>
                    ) : null}
                    <BuyButton
                      product={productWithInquiryOverride}
                      addLineItem={addLineItem}
                      currentVariant={currentVariant}
                      hideShippingStatus={
                        product.store?.productType === 'Gift Card'
                      }
                    />
                    {inquiryOnly !== true &&
                    product.store?.productType !== 'Gift Card' &&
                    currentCountry === 'US' ? (
                      <>
                        <AffirmWrapper>
                          <style jsx global>{`
                            #klarnaPlacement::part(osm-cta) {
                              text-decoration: none;
                            }
                            #klarnaPlacement::part(osm-message) {
                              font-family: 'Inferi', 'Georgia', serif;
                              font-weight: 200;
                              line-height: 18.2px;
                            }
                            #klarnaPlacement::part(osm-cta) {
                              font-family: 'Inferi', 'Georgia', serif;
                              font-weight: 200;
                              line-height: 18.2px;
                            }
                          `}</style>
                          <Affirm price={currentVariant?.sourceData?.priceV2} />
                          <Klarna price={currentVariant?.sourceData?.priceV2} />
                        </AffirmWrapper>
                      </>
                    ) : null}

                    <ProductAccordionsWrapper $isRing={productType === 'Ring'}>
                      {productType === 'Ring' ? (
                        <RingToolsWrapper>
                          <RingSizerButton
                            product={product}
                            variant={currentVariant}
                            mobile
                          />
                          <SizeConverterButton
                            product={product}
                            variant={currentVariant}
                            addLineItem={addLineItem}
                            mobile
                          />
                          {weddingMatch ? (
                            <>
                              <AppointmentsButton mobile />
                              <CustomizeButton
                                product={product}
                                variant={currentVariant}
                                mobile
                              />
                            </>
                          ) : null}
                        </RingToolsWrapper>
                      ) : null}
                      {description || optionDescriptions.length ? (
                        <Accordion label="Description">
                          {description ? description : null}
                          {optionDescriptions.length
                            ? optionDescriptions.map((description) => (
                                <RichText
                                  key={description._key || 'some-key'}
                                  body={description.descriptionRaw}
                                />
                              ))
                            : null}
                        </Accordion>
                      ) : null}
                      {accordions && !loading
                        ? accordions.map((a) =>
                            a.title ? (
                              <Accordion
                                key={a._key || 'some-key'}
                                label={a.title}
                              >
                                {a.title == 'Shipping' &&
                                currentCountry != 'US' ? (
                                  <RichText body={a.body_intlRaw} />
                                ) : (
                                  <RichText body={a.bodyRaw} />
                                )}
                              </Accordion>
                            ) : null,
                          )
                        : null}
                    </ProductAccordionsWrapper>
                  </ProductInfoWrapper>
                </InfoWrapper>
              </ProductDetails>
            </Column>
          )}
        </ProductPageWrapper>

        {!isLoading && (
          <>
            <ProductDetailFooter
              product={product}
              currentVariant={currentVariant}
            />
            <ProductRecent />
            <ProductRelated product={product} currentVariant={currentVariant} />
          </>
        )}
      </CurrentProductProvider>
    </>
  )
}
